import React from 'react';
import PropTypes from 'prop-types';
import style from './CardData.module.scss';
import Button, { VARIANT } from '../../../../components/Button/Button';
import check from './image/check-circle.svg';
import Image from '../../../../components/Image/Image';
import { RichText } from 'prismic-reactjs';

const CardData = ({ items, primary }) => {
  const {
    image,
    title,
    sub_title,
    heading,
    sub_heading,
    buttontext,
    buttonlink,
    feature_title,
  } = primary;

  return (
    <div className={style.container}>
      <div className={style.cardWrapper}>
        <div className={style.cards}>
          <div className={style.subcontainer}>
            <div className={style.headings}>
              <div className={style.subheading}>
                <Image image={image} />
                <RichText render={title?.richText} />
              </div>
              <RichText render={sub_title?.richText} />

              <RichText render={heading?.richText} />
              <RichText render={sub_heading?.richText} />

              <div className={style.btn}>
                <Button variant={VARIANT.SECONDARY} to={`${buttonlink?.url}`}>
                  {/* <RichText render={buttontext?.richText} /> */}
                  {buttontext?.richText[0]?.text}
                </Button>
              </div>
            </div>
            <div className={style.features}>
              <RichText render={feature_title?.richText} />
              <div className={style.featurelist}>
                <ul className={style.list}>
                  {items?.map((item, index) => {
                    return (
                      <li key={index}>
                        <img src={check} />
                        <RichText render={item?.features?.richText} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

CardData.propTypes = {
  primary: PropTypes.arrayOf.isRequired,
  items: PropTypes.arrayOf.isRequired,
  buttonlink: PropTypes.object,
};

export default CardData;
