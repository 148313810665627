import React from 'react';
import PropTypes from 'prop-types';
import Style from './DSAandReporting.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const DSAandReporting = ({ primary, items }) => {
  const { heading } = primary;

  return (
    <div className={Style.DSAandReporting}>
      <Accordion
        className={Style.accordion}
        allowZeroExpanded
        allowMultipleExpanded
        preExpanded={['a']}
      >
        <AccordionItem className={Style.accordionItem} uuid={'a'}>
          <AccordionItemHeading className={Style.accordionItemHeading}>
            <AccordionItemButton className={Style.accordionItemButton}>
              <div className={Style.heading}>
                <RichText render={heading.richText} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={Style.accordionItemPanel}>
            <div className={Style.content}>
              {items?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 0
                          ? Style.featuretitleRow
                          : Style.featureTitleRow
                      }
                      key={index}
                    >
                      <div className={Style.featuretitle}>
                        <RichText render={item?.feature_title?.richText} />
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_free_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_small_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_business_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_advance_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

DSAandReporting.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default DSAandReporting;
