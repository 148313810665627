import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import style from './Hero.module.scss';
import classNames from 'classnames';

const Hero = ({ primary, sliceType, togglePeriod, isMobile }) => {
  const { title, subtitle } = primary;

  return (
    <div className={style.wrapper}>
      {sliceType === 'hero_business' ? (
        <div className={style.container}>
          <div className={style.title}>
            <RichText render={title.richText} />
          </div>
          <div className={style.togglewrpper}>
            <label className={style.label}>
              <input
                type="checkbox"
                checked={isMobile}
                onChange={togglePeriod}
              />
              <span
                className={classNames(style.tabone, {
                  [style.activetabone]: !isMobile,
                })}
              >
                Web
              </span>
              <span
                className={classNames(style.tabtwo, {
                  [style.activetabtwo]: isMobile,
                })}
              >
                Mobile
              </span>
            </label>
          </div>
          <div className={style.description}>
            <RichText render={subtitle.richText} />
          </div>
        </div>
      ) : (
        <div className={style.container}>
          {/* <div className={style.description}>
            <RichText render={subtitle.richText} />
          </div> */}
        </div>
      )}
    </div>
  );
};

Hero.propTypes = {
  sliceType: PropTypes.string,
  primary: PropTypes.object.isRequired,
  togglePeriod: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default Hero;
