import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './BussinessCardsSwitcher.module.scss';

const BussinessCardsSwitcher = ({ isAnnual, togglePeriod, primary }) => {
  const { anualperiodtogglelabel } = primary;

  const getPeriod = () => {
    return isAnnual ? 'annual' : 'monthly';
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div
          className={classnames(style.title, {
            [style.selected]: true,
          })}
        >
          <span className={style.name}>
            {getPeriod() === 'monthly' ? 'monthly' : 'annual'}
          </span>
        </div>

        <label className={style.label}>
          <input type="checkbox" checked={isAnnual} onChange={togglePeriod} />
          <span className={style.icon}></span>
          <span
            className={classnames(style.pointer, {
              [style.selected]: isAnnual,
            })}
          ></span>
        </label>

        <div
        // className={classnames(style.title, {
        //   [style.selected]: isAnnual,
        // })}
        >
          <span className={`${style.name} ${style.duration_details_wrap}`}>
            {`${anualperiodtogglelabel.richText[0].text} `}

            <span className={style.duration_details}>
              ({primary?.widget_currency_billing_title?.richText[0]?.text})
            </span>
          </span>{' '}
        </div>
      </div>
    </div>
  );
};

BussinessCardsSwitcher.propTypes = {
  businessToggle: PropTypes.array.isRequired,
  primary: PropTypes.object.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  togglePeriod: PropTypes.func.isRequired,
};

export default BussinessCardsSwitcher;
