import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import style from './Comparison.module.scss';

const Comparison = ({ primary, items, isMobile, currency, isAnnual }) => {
  const { heading } = primary;

  let itemstoShow;

  if (!isMobile) {
    itemstoShow = items.slice(0, 4);
  } else {
    itemstoShow = items.slice(4, 7);
  }

  return (
    <div className={style.Comparison}>
      <div className={style.container}>
        <div className={style.heading}>
          <RichText render={heading.richText} />
        </div>
        <div className={style.plans}>
          <div className={style.blank}></div>
          {itemstoShow?.map((item, index) => (
            <div
              className={
                index === 0
                  ? style.planOne
                  : index === 1
                  ? style.planTwo
                  : index === 2
                  ? style.planThree
                  : style.planFour
              }
              key={index}
            >
              <div className={style.planType}>
                <RichText render={item.plantype.richText} />
              </div>
              <div className={style.innerBox}>
                <div className={style.planAmount}>
                  <h3>
                    {currency === 'Euros €'
                      ? `€${
                          isAnnual
                            ? item.planamount.richText[0].text.match(/\d+/)[0] *
                              10
                            : item.planamount.richText[0].text.match(/\d+/)[0]
                        }`
                      : `$${
                          isAnnual
                            ? item.planamount.richText[0].text.match(/\d+/)[0] *
                              10
                            : item.planamount.richText[0].text.match(/\d+/)[0]
                        }`}
                  </h3>
                </div>
                <div className={style.planValidity}>
                  <RichText render={item.planvalidity.richText} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Comparison.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  currency: PropTypes.any,
  isAnnual: PropTypes.any,
};

export default Comparison;
