import React from 'react';
import PropTypes from 'prop-types';
import Style from './Policies.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const Policies = ({ primary, items }) => {
  const { heading } = primary;

  return (
    <div className={Style.Policies}>
      <Accordion
        className={Style.accordion}
        allowZeroExpanded
        allowMultipleExpanded
        preExpanded={['a']}
      >
        <AccordionItem className={Style.accordionItem} uuid={'a'}>
          <AccordionItemHeading className={Style.accordionItemHeading}>
            <AccordionItemButton className={Style.accordionItemButton}>
              <div className={Style.heading}>
                <RichText render={heading.richText} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={Style.accordionItemPanel}>
            <div className={Style.content}>
              {items?.slice(0, 3)?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 0
                          ? Style.featuretitleRow
                          : Style.featureTitleRow
                      }
                      key={index}
                    >
                      <div className={Style.featuretitle}>
                        <RichText
                          render={item?.featuretitlefortext?.richText}
                        />
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.text_for_free_plan.richText.length > 0 ? (
                          <>
                            <RichText
                              render={item?.text_for_free_plan?.richText}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.text_for_small_plan.richText.length > 0 ? (
                          <>
                            <RichText
                              render={item?.text_for_small_plan?.richText}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.text_for_business_plan.richText.length > 0 ? (
                          <>
                            <RichText
                              render={item?.text_for_business_plan?.richText}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.text_for_advance_plan.richText.length > 0 ? (
                          <>
                            <RichText
                              render={item?.text_for_advance_plan?.richText}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {item?.featuretitle?.richText.length > 0 && (
                      <div
                        className={
                          index % 2 === 0
                            ? Style.featuretitleRow
                            : Style.featureTitleRow
                        }
                        key={index}
                      >
                        <div className={Style.featuretitle}>
                          <RichText render={item?.featuretitle?.richText} />
                        </div>
                        <div className={Style.lawsforplan}>
                          {item?.for_free_plan === true ? (
                            <>
                              <img src={`${check}`} alt="check" />
                            </>
                          ) : (
                            <img src={`${cross}`} alt="cross" />
                          )}
                        </div>
                        <div className={Style.lawsforplan}>
                          {item?.for_small_plan === true ? (
                            <>
                              <img src={`${check}`} alt="check" />
                            </>
                          ) : (
                            <img src={`${cross}`} alt="cross" />
                          )}
                        </div>
                        <div className={Style.lawsforplan}>
                          {item?.for_business_plan === true ? (
                            <>
                              <img src={`${check}`} alt="check" />
                            </>
                          ) : (
                            <img src={`${cross}`} alt="cross" />
                          )}
                        </div>
                        <div className={Style.lawsforplan}>
                          {item?.for_advance_plan === true ? (
                            <>
                              <img src={`${check}`} alt="check" />
                            </>
                          ) : (
                            <img src={`${cross}`} alt="cross" />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              {items?.slice(3, 4)?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 1
                          ? Style.featuretitleRow
                          : Style.featureTitleRow
                      }
                      key={index}
                    >
                      <div className={Style.featuretitle}>
                        <RichText render={item?.featuretitle?.richText} />
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_free_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_small_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_business_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_advance_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

Policies.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Policies;
