import React from 'react';
import PropTypes from 'prop-types';
import Style from './MobileComparison.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const MobileComparison = ({ primary, items, slice_type }) => {
  const {
    heading,
    featuretitle,
    text_for_starter_plan,
    text_for_scale_plan,
    text_for_growth_plan,
  } = primary;

  return (
    <div className={Style.MobileComparison}>
      <Accordion
        className={Style.accordion}
        allowZeroExpanded
        allowMultipleExpanded
        preExpanded={['a']}
      >
        <AccordionItem className={Style.accordionItem} uuid={'a'}>
          <AccordionItemHeading className={Style.accordionItemHeading}>
            <AccordionItemButton className={Style.accordionItemButton}>
              <div className={Style.heading}>
                <RichText render={heading.richText} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={Style.accordionItemPanel}>
            <div className={Style.content}>
              {slice_type === 'mobilecomparisonwithtext' && (
                <div className={Style.featureTitleRow}>
                  <div className={Style.featuretitle}>
                    <RichText render={featuretitle?.richText} />
                  </div>
                  <div className={Style.lawsforplan}>
                    <RichText render={text_for_starter_plan?.richText} />
                  </div>
                  <div className={Style.lawsforplan}>
                    <RichText render={text_for_growth_plan?.richText} />
                  </div>
                  <div className={Style.lawsforplan}>
                    <RichText render={text_for_scale_plan?.richText} />
                  </div>
                </div>
              )}
              {items?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 0
                          ? Style.featuretitleRow
                          : Style.featureTitleRow
                      }
                      key={index}
                    >
                      <div className={Style.featuretitle}>
                        <RichText render={item?.featuretitle?.richText} />
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_starter_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_growth_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_scale_plan === true ? (
                          <>
                            <img src={`${check}`} alt="check" />
                          </>
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

MobileComparison.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  slice_type: PropTypes.string,
};

export default MobileComparison;
